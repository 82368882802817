import '../styles/password.scss'
import { Main } from "../components/layout/Main"
import { BreadcrumbType } from "../types/types"
import { Button, Form, Input, Select, Space, Typography } from 'antd'
import api from '../services/api'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import {  useNavigate } from "react-router-dom"

const items: BreadcrumbType[] = [
    {
        title: 'Home'
    },
    {
        title: 'Festa',
    },
]

type FormValues = {
    choise: string | undefined
}

type PartyType = {
    id: string
    user_id: string
    choise: string
    children: string
    next: string
    invoice_id: string
    invoice: {
        id: string
        pagarme_id: string
        value: string
        status: string
    }

}

const { Option } = Select

export function Party() {
    const history = useNavigate()
    const [form] = Form.useForm()
    const [party, setParty] = useState<PartyType>()
    const [disableSelectChildren, setDisableSelectChildren] = useState(true)
    const [isDisabled, setIsDisabled] = useState(true)
    

    const onFinish = async (values: FormValues) => {
        if(!party)
            return 
        const { invoice,...updatedParty } = party
        await api.post('party/add', new URLSearchParams(
            { ...updatedParty }
        ))
            .then((response) => {
                if (response.data.msg) {
                    toast.success(response.data.msg, { autoClose: 2000 })
                    history('/')
                } else {
                    toast.error(response.data)
                }
            })
            .catch((e) => {
                if (e.response) {
                    // Is this the correct way?
                    toast.error(e.response?.data.error)
                }

            })
    }

    const changeSelect = async (value: string) => {
        if (party) {
            let updatedParty
            updatedParty = { ...party }
            updatedParty.choise = value
            if(value==="0"){
                form.setFieldsValue({
                    children: "0"
                })
                setDisableSelectChildren(true)
                updatedParty.children = "0"
            }else{
                setDisableSelectChildren(false)
            }
            setParty(updatedParty)
        }
    }

    const changeSelectChildren = async (value: string) => {
        if (party) {
            let updatedParty
            updatedParty = { ...party }
            updatedParty.children = value
            setParty(updatedParty)
        }
    }

    const changeSelectNext = async (value: string) => {
        if (party) {
            let updatedParty
            updatedParty = { ...party }
            updatedParty.next = value
            setParty(updatedParty)
        }
    }

    

    useEffect(() => {
        async function loadData() {
            try {
                const response = await api.get('party/get')
                setParty(response.data)
                form.setFieldsValue({
                    choise: response.data.choise,
                    children: response.data.children,
                    next: response.data.next
                })
                if(response.data.choise==="0"){
                    setDisableSelectChildren(true)
                }

            } catch (error) {
                console.error('Erro ao carregar dados do usuário:', error)
            }
        }
        loadData()
    }, [form])
    return (
        <Main itemBreadcrumb={items}>
            <Space direction="vertical" >
                <Typography.Title level={4}>
                    Confirmação da Festa e Próxima Campeonato
                </Typography.Title>

                <Form
                    form={form}
                    name="party-form"
                    className="party-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="next"
                        label="Vou jogar o próximo campeonato"
                        rules={[
                            {
                                required: true,
                                message: 'Escolha uma opção',
                            },
                        ]}
                    >
                        <Select defaultValue="Escolha uma opção" style={{ width: 280 }} disabled={true} onChange={changeSelectNext}>
                            <Option value="1">Sim</Option>
                            <Option value="2">Não</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="choise"
                        label="Acompanhante maior de 12 anos"
                        rules={[
                            {
                                required: true,
                                message: 'Escolha uma opção',
                            },
                        ]}
                    >
                        <Select defaultValue="Escolha uma opção" style={{ width: 280 }} disabled={isDisabled} onChange={changeSelect}>
                            <Option value="0">Não vou a festa</Option>
                            <Option value="1">Não vou levar maior de 12 anos</Option>
                            <Option value="2">Vou levar 1 pessoa maior de 12 anos</Option>
                            <Option value="3">Vou levar 2 pessoas maiores de 12 anos</Option>
                            <Option value="4">Vou levar 3 pessoas maiores de 12 anos</Option>
                            <Option value="5">Vou levar 4 pessoas maiores de 12 anos</Option>
                            <Option value="6">Vou levar 5 pessoas maiores de 12 anos</Option>
                            <Option value="7">Vou levar 6 pessoas maiores de 12 anos</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="children"
                        label="Crianças menores de 12 anos"
                    >
                        <Select defaultValue="0" style={{ width: 280 }} disabled={disableSelectChildren} onChange={changeSelectChildren}>
                            <Option value="0">Não vou levar criança</Option>
                            <Option value="1">Vou levar 1 criança menos de 12 anos</Option>
                            <Option value="2">Vou levar 2 crianças menores de 12 anos</Option>
                            <Option value="3">Vou levar 3 crianças menores de 12 anos</Option>
                            <Option value="4">Vou levar 4 crianças menores de 12 anos</Option>
                            <Option value="5">Vou levar 5 crianças menores de 12 anos</Option>
                            <Option value="6">Vou levar 6 crianças menores de 12 anos</Option>
                        </Select>
                    </Form.Item>
                    {party?.invoice?.status === "Pago" ?
                        <h4>Seu pagamento foi efetuado com sucesso e sua presença está confirmada!</h4>
                        :
                        <Form.Item className="login-submit">
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                Salvar
                            </Button>
                        </Form.Item>
                    }

                </Form>

            </Space>
        </Main>
    )
}


